<template>
  <div>
    <v-list
      v-if="elementActive === null"
      dense
      nav
      class="py-0"
    >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        @click="elementActive = item.element"
      >
        <v-icon>{{ item.icon }}</v-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-else-if="elementActive !== null">
      <component :is="elementActive" creator="text" @add="emit" @renderAll="$emit('renderAll')" :obj="objSelected"
                 :refreshSetting="refreshSetting" :fonts="fonts" :fcanvas="fcanvas" :fontsLoading="fontsLoading"></component>
      <v-btn v-if="objSelected" @click="removeObj" v-t="'General.Delete'"></v-btn>
    </div>
  </div>
</template>

<script>
  import DrawerRightAdd from './DrawerRightAdd'
  import DrawerRightCalques from './DrawerRightCalques'
  import SettingsSquare from './SettingsSquare'
  import SettingsText from './SettingsText'
  import SettingsLine from './SettingsLine'
  import SettingsSvg from './SettingsSvg'
  import SettingsImage from './SettingsImage'
  import SettingsMask from './SettingsMask'

  export default {
    props: [
      'objSelected',
      'refreshSetting',
      'fonts',
      'fcanvas',
      'fontsLoading'
    ],
    components: {
      SettingsMask
    },
    name: 'DrawerRightText',
    data () {
      return {
        elementActive: null,
        items: [
          { title: 'Ajouter un élément', icon: 'mdi-apps', element: DrawerRightAdd },
          { title: 'Calques', icon: 'mdi-content-copy', element: DrawerRightCalques }
        ]
      }
    },
    watch: {
      objSelected () {
        this.updateElementActive()
      }
    },
    created () {
      this.updateElementActive()
    },
    methods: {
      emit (obj) {
        this.$emit('add', obj)
      },
      removeObj () {
        this.$emit('remove', this.objSelected)
      },
      updateElementActive () {
        this.elementActive = null
        if (this.objSelected != null) {
            switch (this.objSelected.type) {
                case 'rect':
                case 'square':
                  this.elementActive = SettingsSquare
                  break
                case 'line':
                    this.elementActive = SettingsLine
                    break
                case 'text':
                case 'i-text':
                    this.elementActive = SettingsText
                    break
                case 'group':
                case 'svg':
                  this.elementActive = SettingsSvg
                  break
                case 'image':
                  this.elementActive = SettingsImage
                  break
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
