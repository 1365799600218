<template>
  <div>
    <v-list-item @click="$parent.elementActive = null">
      <v-list-item-content>
        <v-btn v-t="'General.Return'"></v-btn>
      </v-list-item-content>
    </v-list-item>
    <v-list
      dense
      nav
      class="py-0"
      id="listeCalques"
    >
      <v-list-item
        link
        @click="selObj(obj)"
        @mouseover="hoverObj(obj)"
        @mouseleave="unhoverObj"
        v-for="(obj, index) in objListLocal"
        :key="obj.type + index"
      >
        <v-list-item-action style="cursor: move">
          <v-icon class="icon-move">mdi-cursor-move</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{obj.type}}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action style="flex-direction: row">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click.stop="clone(obj)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span v-t="'DrawerRightCalques.CloneObject'"></span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click.stop="remove(obj)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span v-t="'DrawerRightCalques.DeleteObject'"></span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import Sortable from 'sortablejs'

  export default {
    name: 'DrawerRightCalques',
    props: [
      'fcanvas'
    ],
    data () {
      return {
        objListLocal: []
      }
    },
    mounted () {
      this.objListLocal = this.fcanvas.getObjects().reverse().filter((obj) => { return obj.name !== 'backPattern' })
      Sortable.create(document.getElementById('listeCalques'), {
        handle: '.icon-move',
        animation: 150,
        onEnd: (evt) => {
          var objs = this.fcanvas.getObjects().reverse()
          var objToMove = objs[evt.oldIndex]

          if (evt.newIndex > evt.oldIndex) {
            for (let i = evt.oldIndex; i < evt.newIndex; i++) {
              objs[i] = objs[i + 1]
            }
          } else if (evt.newIndex < evt.oldIndex) {
            for (let i = evt.oldIndex; i > evt.newIndex; i--) {
              objs[i] = objs[i - 1]
            }
          }

          objs[evt.newIndex] = objToMove

          this.fcanvas._objects = [...objs].reverse()
        }
      })
    },
    computed: {
      allObjects () {
        return this.fcanvas.getObjects()
      }
    },
    methods: {
      clone (o) {
        o.clone((cloned) => {
          cloned.set({
            left: cloned.left + 10,
            top: cloned.top + 10
          })
          this.fcanvas.add(cloned)
          this.fcanvas.requestRenderAll()
        })
      },
      selObj (o) {
        this.$parent.$parent.$parent.hover = false
        this.fcanvas.setActiveObject(o)
        this.$parent.$parent.$parent.select(o)
        this.fcanvas.renderAll()
      },
      hoverObj (o) {
        this.$parent.$parent.$parent.hover = true
        this.fcanvas.setActiveObject(o)
        this.fcanvas.renderAll()
      },
      unhoverObj () {
        if (this.$parent.$parent.$parent.hover) {
          this.fcanvas.discardActiveObject()
          this.$parent.$parent.$parent.hover = false
          this.fcanvas.renderAll()
        }
      },
      bringToFront (obj) {
        this.fcanvas.bringToFront(obj)
      },
      sendToBack (obj) {
        this.fcanvas.sendToBack(obj)
      },
      remove (calque) {
        this.fcanvas.remove(calque)
        this.objListLocal = this.objListLocal.filter((obj) => { return obj !== calque })
      }
    }
  }
</script>

<style scoped>

</style>
