<template>
  <div>
    <v-list
        dense
        nav
        class="py-0"
    >
      <v-list-item>
        <v-text-field :label="$t('SettingsItems.Width')" type="number" v-model="size" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field :label="$t('SettingsItems.TopPosition')" type="number" v-model="top" @input="updateObj()" style="margin-right: 35px"></v-text-field>
        <v-text-field :label="$t('SettingsItems.LeftPosition')" type="number" v-model="left" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-text-field :label="$t('SettingsItems.Angle')" type="number" v-model="angle" @input="updateObj()"></v-text-field>
      </v-list-item>
      <v-list-item class="justify-center">
          <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs}">
                  <v-btn color="primary"
                         dark
                         v-bind="attrs"
                         v-on="on"
                         icon
                  >
                      <v-icon>mdi-palette</v-icon>
                  </v-btn>
              </template>
              <v-color-picker
                      class="ma-2"
                      :swatches="swatches"
                      show-swatches
                      v-model="strokeColorPicker"
                      @input="changeStrokeColor(strokeColorPicker)"
              ></v-color-picker>
          </v-menu>
      </v-list-item>
      <v-list-item>
        <v-text-field :label="$t('SettingsItems.BorderThickness')" type="number" :min="0" v-model="strokeWidth" @input="updateObj()"></v-text-field>
      </v-list-item>
      <mask-order-controls :fcanvas="fcanvas" :obj="obj"></mask-order-controls>
    </v-list>
  </div>
</template>

<script>
import MaskOrderControls from '@/components/drawerRight/MaskOrderControls'

export default {
  props: [
    'obj',
    'refreshSetting',
    'fcanvas'
  ],
  name: 'SettingsLine',
  components: { MaskOrderControls },
  data () {
    return {
      size: this.obj.width * this.obj.scaleX,
      stroke: this.obj.stroke,
      strokeWidth: this.obj.strokeWidth,
      left: this.obj.left,
      top: this.obj.top,
      angle: this.obj.angle,
        swatches: [
            ['#FF0000', '#AA0000', '#550000'],
            ['#FFFF00', '#AAAA00', '#555500'],
            ['#00FF00', '#00AA00', '#005500'],
            ['#00FFFF', '#00AAAA', '#005555'],
            ['#0000FF', '#0000AA', '#000055']
        ],
      strokeColorPicker: null
    }
  },
  created () {
    this.obj.on({
      'object:modified': (e) => {
        this.select(e)
      }
    })
      this.stroke = typeof this.obj.stroke === 'object' ? 'rgb(0,0,0)' : this.obj.stroke
      this.obj.set({ stroke: this.stroke })
      if (this.obj.stroke && this.obj.stroke.indexOf('(') !== -1) {
          this.strokeColorPicker = this.rgbToHex(this.obj.stroke)
      } else {
          this.strokeColorPicker = this.obj.stroke
      }
  },
  watch: {
    refreshSetting () {
        this.updateLocalObj()
    },
    obj () {
        this.updateLocalObj()
    }
  },
  methods: {
      rgbToHex (rgb) {
          var rgbvalues = rgb.split('(')[1].split(')')[0].split(',')
          var hexvalues = rgbvalues.map(function (x) {
              x = parseInt(x).toString(16)
              return (x.length === 1 ? '0' + x : x)
          })
          return '#' + hexvalues.join('')
      },
    updateLocalObj () {
        if (this.obj.stroke && this.obj.stroke.indexOf('(') !== -1) {
            this.strokeColorPicker = this.rgbToHex(this.obj.stroke)
        } else {
            this.strokeColorPicker = this.obj.stroke
        }
      this.size = this.obj.width * this.obj.scaleX
      this.colorStroke = this.obj.stroke
      this.strokeWidth = this.obj.strokeWidth
      this.left = this.obj.left
      this.top = this.obj.top
      this.angle = this.obj.angle
    },
      changeStrokeColor (color) {
          this.stroke = color
          this.updateObj()
      },
    updateObj () {
      this.obj.set({
          angle: this.angle,
          left: this.left,
          top: this.top,
          stroke: this.stroke,
          strokeWidth: parseInt(this.strokeWidth)
      })
      this.obj.scale(this.size / this.obj.width)
      this.obj.setCoords()
      this.updateLocalObj()
      this.$emit('renderAll')
    }
  }
}
</script>

<style scoped>

</style>
